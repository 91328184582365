import CV2 from '../Assets/cv2.jpg';
import CV3 from '../Assets/cv3.jpg';
import CV4 from '../Assets/cv4.jpg';
import CV5 from '../Assets/cv5.jpg';

export const SliderData = [
    {
        Image: CV2
    }, {
        Image: CV3
    }, {
        Image: CV4
    }, {
        Image: CV5
    },
    {
        Image: CV2
    }, {
        Image: CV3
    }, {
        Image: CV4
    }, {
        Image: CV5
    },
    {
        Image: CV2
    }, {
        Image: CV3
    }, {
        Image: CV4
    }, {
        Image: CV5
    },
    {
        Image: CV2
    }, {
        Image: CV3
    }, {
        Image: CV4
    }, {
        Image: CV5
    },
]