import React from 'react';
import './Main.css';

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import CV1 from '../../Assets/cv1.jpg';
import CV2 from '../../Assets/cv2.jpg';
import CV3 from '../../Assets/cv3.jpg';
import CV4 from '../../Assets/cv4.jpg';
import CV5 from '../../Assets/cv5.jpg';


const Main = () => {
    return (
        <>
            <div className="main-wrapper">
                <div className="main">
                    <div className="maintitle">
                        {/* <div className="text">Create a professional</div>
                        <ul className='autotext'>
                            <li><span>CV</span></li>
                            <li><span>Resume</span></li>
                            <li><span>CoverLetter</span></li>
                        </ul> */}
                        <h1>Create a professional CV</h1>
                    </div>
                    <div className="maindec">
                        <h3>Fill in the blanks, choose a template and download your CV in minutes.</h3>
                    </div>
                    <div className="main-btn">
                        <button className='btn'>Create CV</button>
                    </div>
                </div>

                <div className="main-swiper">

                    <Swiper
                        slidesPerView={3}
                        spaceBetween={30}
                        loop={true}
                        grabCursor={true}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <img src={CV2} alt="" />
                            <div className="swiperbtn">
                                <button className='animatebtn'><span>Use This</span></button>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={CV3} alt="" />
                            <div className="swiperbtn">
                                <button className='animatebtn'><span>Use This</span></button>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={CV4} alt="" />
                            <div className="swiperbtn">
                                <button className='animatebtn'><span>Use This</span></button>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={CV5} alt="" />
                            <div className="swiperbtn">
                                <button className='animatebtn'><span>Use This</span></button>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={CV2} alt="" />
                            <div className="swiperbtn">
                                <button className='animatebtn'><span>Use This</span></button>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={CV3} alt="" />
                            <div className="swiperbtn">
                                <button className='animatebtn'><span>Use This</span></button>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={CV4} alt="" />
                            <div className="swiperbtn">
                                <button className='animatebtn'><span>Use This</span></button>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={CV5} alt="" />
                            <div className="swiperbtn">
                                <button className='animatebtn'><span>Use This</span></button>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>

            </div>

        </>
    )
}

export default Main