
import ReviewImg1 from '../Assets/reviewimg1.avif';
import ReviewImg2 from '../Assets/reviewimg2.avif';
import ReviewImg3 from '../Assets/reviewimg3.avif';
import ReviewImg4 from '../Assets/reviewimg4.avif';
import ReviewImg5 from '../Assets/reviewimg5.avif';
import ReviewImg6 from '../Assets/sohaib.jpg';

export const ReviewData = [
    {
        Image:ReviewImg1,
        title:'Aqib',
        stitle:'Project Manager',
        dec:'A comprehensive platform with resources and tools for producing high-quality CVs and cover letters.'
    },
    {
        Image:ReviewImg2,
        title:'Hasnain',
        stitle:'HR Professional',
        dec:'Excellent platform with everything you need as a platform with everything.'
    },
    {
        Image:ReviewImg6,
        title:'Sohaib',
        stitle:'Enginer',
        dec:'Intuitive tool that guides you through the process of creating a CV with customisable sections.'
    },
    {
        Image:ReviewImg4,
        title:'Olivia',
        stitle:'Graphic designer',
        dec:'Great website with lots of interesting vacancies. Applying for jobs has never been easier.'
    },
    {
        Image:ReviewImg3,
        title:'Noah',
        stitle:'Freelancer',
        dec:'A must-have for creating professional resumes and cover letters to impress employers and score interviews.'
    },
    {
        Image:ReviewImg5,
        title:'Amelia',
        stitle:'Content Writer',
        dec:'Very helpful tool that is user-friendly, intuitive, and supports job seekers through the entire application process.'
    }
]