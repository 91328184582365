import React from 'react'
import './Work.css';
import Sample from '../../Assets/cv-simplified.png';

const Work = () => {
    return (
        <>
            <div className="work">
                <div className="work-wrapper">
                    <div className="wrok-title">
                        <h1>How it works</h1>
                    </div>
                    <div className="work-t">
                        <div className="work-boxs">
                            <div className="work-box">
                                <div className="work-cricle">
                                    <span>1</span>
                                </div>
                                <div className="work-details">
                                    <h3>Fill in the Blanks</h3>
                                    <h6>
                                        Start by filling in the relevant
                                        information for your CV.
                                    </h6>
                                </div>
                            </div>

                            <div className="work-box">
                                <div className="work-cricle">
                                    <span>2</span>
                                </div>
                                <div className="work-details">
                                    <h3>Pick a Template</h3>
                                    <h6>
                                        Choose a template and customize it
                                        to your own identity.
                                    </h6>
                                </div>
                            </div>

                            <div className="work-box">
                                <div className="work-cricle">
                                    <span>3</span>
                                </div>
                                <div className="work-details">
                                    <h3>Download your CV</h3>
                                    <h6>
                                        Easily download your CV and edit afterwards .
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="work-b">
                        <img src={Sample} alt="" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Work