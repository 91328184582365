import './App.css';
import CoverLetter from './Pages/CoverLetter/CoverLetter';
import Cv from './Pages/CV/Cv';
import Resume from './Pages/Resume/Resume';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home/Home';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/cv' element={<Cv />} />
          <Route path='/resume' element={<Resume />} />
          <Route path='/CoverLetter' element={<CoverLetter />} />
        </Routes>
      </BrowserRouter>

    </>

  );
}

export default App;
