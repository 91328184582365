import React from 'react';
import './Review.css';
import { UisStar } from '@iconscout/react-unicons-solid';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { ReviewData } from '../../Data/ReviewData';


const Review = () => {
    return (
        <>
            <div className="review-wrapper">
                <div className="review">
                    <div className="review-t">
                        <h1>Reviews</h1>
                        <h4>4.6<span>/ 5</span>  <span><UisStar /> <UisStar /> <UisStar /> <UisStar /> <UisStar /></span></h4>
                        <h6>Based on 16,307 <span>Trustpilot</span> reviews</h6>
                    </div>
                    <div className="review-b">
                        <Swiper
                            slidesPerView={3}
                            spaceBetween={30}
                            loop={true}
                            grabCursor={true}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={true}
                            modules={[Pagination, Navigation]}
                            className="mySwiper"
                        >
                            {
                                ReviewData.map((item, i) => {
                                    return (
                                        <SwiperSlide>
                                            <div className="reviewSlider" key={i}>
                                                <div className="reviewSImgbox">
                                                    <img src={item.Image} alt="" />
                                                </div>
                                                <div className="reviewSTitle">
                                                    <h2>{item.title}</h2>
                                                    <h3>{item.stitle}</h3>
                                                </div>
                                                <div className="reviewSdec">
                                                    <p>{item.dec}</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    )
                                })
                            }

                        </Swiper>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Review