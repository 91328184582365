import React, { useState } from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [dropDown, setDropdown] = useState(false);
    const [navbarcolor, setNavbarColor] = useState(false);

    const ChangeColor = () => {
        if (window.scrollY >= 90) {
            setNavbarColor(true)
        } else {
            setNavbarColor(false)
        }
    }
    window.addEventListener('scroll', ChangeColor)

    return (
        <>
            <div className={'navbar-wrapper ' + (navbarcolor ? 'navbar-visible' : '')}>
                <div className={"navbar " + (navbarcolor ? 'navbar-v' : '')}>
                    <div className="navbar-logo">
                        <Link to='/'>
                            <h1>Algotech</h1>
                            <span>pro</span>
                        </Link>
                    </div>
                    <div className="navbar-menu">
                        <ul>
                            <li onClick={() => setDropdown(!dropDown)}>Tools</li>
                            <li>Pricing</li>
                            <li>FAQ</li>
                        </ul>
                    </div>

                    <div className="navbar-r">
                        <button className='btn'>Login/Register</button>
                    </div>
                    <div className={"dropDown " + (dropDown ? 'dropDownVisible' : '')} >
                        <ul>
                            <li className='minidrop1'>Resume
                                <ol className='minidropdown1'>
                                    <Link to='/resume'><li>Template</li></Link>
                                    <li>Articals</li>
                                </ol>
                            </li>
                            <li className='minidrop2'>CV
                                <ol className='minidropdown2'>
                                <Link to='/cv'><li>Template</li></Link>
                                    <li>Articals</li>
                                </ol>
                            </li>
                            <li className='minidrop3'>Cover letter
                                <ol className='minidropdown3'>
                                <Link to='/coverLetter'><li>Template</li></Link>
                                </ol>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Navbar