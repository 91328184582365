import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <>
            <div className="footer-wrapper">
                <div className="footer">
                    <div className="footer-boxs">
                        <div className="footer-box">
                            <h2>Resources</h2>
                            <ul>
                                <li>Resume</li>
                                <li>CV</li>
                                <li>Cover letter</li>
                            </ul>
                        </div>

                        <div className="footer-box">
                            <h2>Product </h2>
                            <ul>
                                <li>Pricing</li>
                                <li>Reviews</li>
                            </ul>
                        </div>

                        <div className="footer-box">
                            <h2>Support</h2>
                            <ul>
                                <li>FAQ</li>
                                <li>Contact us</li>
                                <li>Terms of Use</li>
                                <li>Privacy</li>
                            </ul>
                        </div>
                    </div>
                    <div className="copyright">
                        <h2>@ 2050  AlgotechCV</h2>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer