import React from 'react';
import Footer from '../../Components/Footer/Footer';
import Navbar from '../../Components/Navbar/Navbar';
import Review from '../../Components/Reviews/Review';
import Work from '../../Components/Work/Work';
import './Resume.css';

const Resume = () => {
    return (
        <>
            <Navbar />
            <div className="resume-wrapper">
                <div className="resume">
                    <h1>Resume templates</h1>
                    <h3>Impress potential employers with a compelling resume template.</h3>
                </div>
            </div>
            <div className="ResumeTemplate">
                <div className="ResumeTemplateBoxs">
                    <div className="ResumeTemplateBox"></div>
                    <div className="ResumeTemplateBox"></div>
                    <div className="ResumeTemplateBox"></div>
                    <div className="ResumeTemplateBox"></div>
                    <div className="ResumeTemplateBox"></div>
                    <div className="ResumeTemplateBox"></div>
                    <div className="ResumeTemplateBox"></div>
                    <div className="ResumeTemplateBox"></div>
                    <div className="ResumeTemplateBox"></div>
                </div>
                <div className='ResumeNext'>
                    <div>
                        <p>1 <span>2</span></p>
                    </div>
                    <div>
                        <button className='btn'>Next</button>
                    </div>
                </div>

            </div>
            <Work />
            <Review />
            <Footer />

        </>
    )
}

export default Resume