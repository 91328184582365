import React from 'react';
import { SliderData } from '../../Data/SliderData';
import './Impress.css';


const Impress = () => {
    return (
        <>
            <div className="impress-wrapper">
                <div className="impress">
                    <div className="impress-l">
                        <h1>Impress employers with your CV</h1>
                        <h6>Step-by-step guidance to create a professional CV in minutes.</h6>
                        <button className="btn">Create CV</button>
                    </div>
                    <div className="impress-r">
                        <div className="cv-boxs">
                            <div className="cv-box">
                                {
                                    SliderData.map((item, i) => {
                                        return (
                                            <div className="CVimgbox CVup" key={i}>
                                                <img src={item.Image} alt="" />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="cv-box">
                                {
                                    SliderData.map((item, i) => {
                                        return (
                                            <div className="CVimgbox CVdown" key={i}>
                                                <img src={item.Image} alt="" />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Impress