import React from 'react';
import Footer from '../../Components/Footer/Footer';
import Impress from '../../Components/Impress/Impress';
import Main from '../../Components/Main/Main';
import Navbar from '../../Components/Navbar/Navbar';
import Review from '../../Components/Reviews/Review';
import Work from '../../Components/Work/Work';
import './Home.css';

const Home = () => {
  return (
    <>
    <Navbar/>
    <Main/>
    <Work/>
    <Review/>
    <Impress/>
    <Footer/>
    </>
  )
}

export default Home